<template>
  <b-card>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :fields-time-line="fieldsTimeLine"
      :baseroute="baseroute"
      :isedit="false"
      :isdelete="isDelete"
      :is-action="isAction"
      @readData="showData"
    >
      <template slot="buttonaction">
        <!-- <b-button
          style="float:right;"
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="actionUpload"
        >
          
          <span>Upload Berkas</span>
        </b-button> -->
        <b-button
          v-if="model.status=='created'"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          :disabled="loadingPrint"
          @click.prevent="printOwnRisk"
        >
          <feather-icon icon="PrinterIcon" /> 
          <span v-if="loadingPrint">
            Loading...
          </span>
          <span v-else> Invoice</span>
        </b-button>
        <b-button
          v-if="isAction"
          class="mr-1"
          size="sm"
          variant="info"
          type="button"
          @click.prevent="action(
            model.status == 'created' ? 'submit' : 
            model.status=='submitted'? 'approve' : 'pay'
          )"
          >
          <span>{{ model.status=='created'?'Ajukan':model.status=='submitted'?'Setujui':'Bayar' }}</span>
        </b-button>
        <b-button
          v-if="isCancel"
          class="mr-1"
          size="sm"
          variant="warning"
          type="button"
          @click.prevent="action(model.status=='created'?'reject':'reject')"
        >
          <span>{{ model.status=='created'?'Batalkan':'Tolak' }}</span>
        </b-button>
      </template>
    </base-info>

    <b-row>
      <b-col cols="12">
        <hr>
      </b-col>
      <b-col cols="12">
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="TruckIcon" />
              Daftar Perawatan
            </template>
            <b-table
                striped
                responsive
                :items="itemPerawatan"
                :fields="fieldsPerawatan"
            >
            <template #cell(cost)="data">
              <span>
                {{ 'Rp'+formatPrice(data.value) }}
              </span>
            </template>
            </b-table>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="FileIcon" />
              Daftar Dokumen
            </template>
            <document />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BCol, BRow, BTable, BOverlay, BButton, BTabs, BTab} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfoV3.vue'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import Document from './IndexDocument.vue'
import basetable from '@/views/base/BaseTableV4.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable, 
    BOverlay,
    BButton,
    BTabs, 
    BTab,
    Document,
    basetable,
  },

  data() {
    return {
      user_type:null,
      user:null,
      dataurl:"/billing",
      baseroute:"billing-rent",
      fields: [
        { key: 'number', label: 'Nomor Penagihan', sortable: true },
        { key: 'billingable.number', label: 'Nomor Klaim', sortable: true },
        { key: 'billingable.vehicle.number', label: 'NOPOL Kendaraan', sortable: true },
        { key: 'billingable.spb.number', label: 'Nomor SPB', sortable: true },
        { key: 'billingable.spb.contract_subject', label: 'Judul Kontrak', sortable: true },
        { key: 'billingable.spb.contract_number', label: 'Nomor Kontrak', sortable: true },
        { key: 'billingable.date', label: 'Tanggal Pengajuan', type:'date'},
        { key: 'status', label: 'Status', type:'spanKey', 
          data:{created:{color:'bg-secondary',value:'Dibuat'}, 
            submitted:{color:'bg-warning',value:'Diajukan'}, 
            approved:{color:'bg-info',value:'Disetujui'}, 
            paid:{color:'bg-success',value:'Selesai'},
            rejected:{color:'bg-danger',value:'Ditolak'},
          }},
      ],
      itemPerawatan : null,
      fieldsPerawatan: [
        { key: 'code', label: 'Kode'},
        { key: 'name', label: 'Perawatan'},
        { key: 'item_name', label: 'Item Perawatan'},
        { key: 'cost', label: 'Biaya' },
        { key: 'description', label: 'Keterangan' },
      ],
      fieldsTimeLine:[],
      model:[],
      isDelete:false,
      isAction:false,
      isCancel:false,
      loadingPrint: false,
    }
  },
  created(){
    if(this.$route.meta.user){
      this.user_type = this.$route.meta.user.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$route.meta.user.contact
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    showData(result){
      this.model = result
      this.itemPerawatan = []
      for (var maintenance of this.model.billingable.maintenances){
        for (var detail of maintenance.details){
          this.itemPerawatan.push({
            code: maintenance.code,
            name: maintenance.name,
            item_name: detail.item.name,
            cost: detail.item.cost,
            description: detail.item.description,
          });
        }
      }
      this.fieldsTimeLine = []
      for(let i=0; i<result.tracks.length; i++){
        this.fieldsTimeLine.push({
          title:result.tracks[i]['description'],
          description:'Diproses pada '+moment(result.tracks[i]['created_at']).format('DD MMMM YYYY HH:mm:ss')
        })
      }
      this.fieldsTimeLine.is_reverse = true
      this.setButton()
    },
    setButton(){
      if(this.user_type != 'vendor'){
        this.isAction = this.model.status != 'rejected' && this.model.status != 'paid'?true:false
        this.isCancel = this.model.status == 'submitted'?true:false
      }else{
        this.isAction = this.model.status == 'created'?true:false
        this.isCancel = false
      }
      this.isDelete = this.model.status == 'created'?true:false
    },
    actionUpload(){
      this.$router.push({ name: this.baseroute+'-upload'})
    },
    action(action){
      const id = this.$route.params.id
      
      this.$bvModal
        .msgBoxConfirm('Are you sure ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'info',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.put(this.dataurl+'/'+id+'/'+action)
              .then(() => {
                this.$refs.baseinfo.fetchData()
            })
          }
        })
    },
    async printOwnRisk() {
      this.loadingPrint = true;
      let print = await this.$http.get('billing/'+this.$route.params.id+'/risk/print')
      if(print){
        this.loadingPrint = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invoice Own Risk telah diprint',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        window.open(print.data.filename)
      }
    },
  }
}
</script>
